.fade-anim img {
  animation: fadeAnim 0.5s ease-out;
}
@keyframes fadeAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
.team {
  background-image: url("../src/assets/tean.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
.home {
  background-image: url("../src/assets/herobg.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}
.partner {
  background-color: #3b4480;
  color: white;
}
.clippath {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.dropShadow {
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}
